<template>
  <b-modal
    id="modal-create-job"
    ref="modal-create-job"
    centered="centered"
    :title="(job.id ? 'Modifier' : 'Ajouter') + ` un métier`"
    @ok="create"
  >
    <div v-if="isCreatingJob">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formCreateJob">
      <b-row>
        <b-col cols="12" class="d-flex mb-1">
          <b-form-group label="Libellé *" label-for="Libellé" style="flex: 1">
            <validation-provider
              #default="{ errors }"
              name="Libellé"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="job.label"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
                v-on:keyup.enter="create"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 mx-0">
        <b-button
          class="mr-1 float-left"
          v-if="job.id && archive"
          variant="outline-danger"
          @click="deleteJobLocal(job.id, job.label)"
          ><feather-icon icon="TrashIcon"
        /></b-button>
        <b-button
          class="float-left"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-if="job.id && !archive"
          variant="outline-danger"
          @click="archiveJobLocal(job.id, job.label)"
          ><feather-icon icon="ArchiveIcon"
        /></b-button>
        <b-button
          class="float-left"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-if="job.id && archive"
          variant="outline-warning"
          @click="restoreJobLocal(job.id, job.label)"
          ><feather-icon icon="ArrowDownIcon"
        /></b-button>
        <b-button class="float-right" variant="primary" @click="ok()">{{
          job.id ? "Modifier" : "Ajouter"
        }}</b-button>
        <b-button
          class="float-right mr-1"
          variant="outline-primary"
          @click="cancel()"
          >Annuler</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      job: {
        label: "",
        institutionId: this.$store.getters.institutionSelected.id,
      },
    };
  },
  props: {
    archive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    popupCreateJobEvent(id) {
      if (id > 0) {
        this.getJobById({
          jobId: id,
        }).then((res) => {
          this.job = res;
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.job = {
        label: "",
        institutionId: this.$store.getters.institutionSelected.id,
      };
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formCreateJob.validate().then(async (success1) => {
        if (success1) {
          if (this.job.id) {
            await this.updateJob({
              job: this.job,
            }).then((res) => {
              this.$nextTick(() => {
                this.$emit("refreshGrid");
                this.$refs["modal-create-job"].toggle("#toggle-btn");
              });
            });
          } else {
            await this.createJob({
              job: this.job,
            }).then((res) => {
              this.$nextTick(() => {
                this.$emit("refreshGrid");
                this.$refs["modal-create-job"].toggle("#toggle-btn");
              });
            });
          }
        }
      });
    },
    archiveJobLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer ce métier en consultant la liste de vos archives.",
          {
            title:
              'Êtes-vous sûr de vouloir archiver le métier "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            await this.archiveJobs({
              jobIds: [id],
            }).then((res) => {
              this.$nextTick(() => {
                this.$emit("refreshGrid");
                this.$bvModal.hide("modal-create-job");
              });
            });
          }
        });
    },
    restoreJobLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez ré-utiliser ce métier dans tous les modules de votre application",
          {
            title:
              'Êtes-vous sûr de vouloir restaurer le métier "' + label + '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            await this.restoreJobs({
              jobIds: [id],
            }).then((res) => {
              this.$nextTick(() => {
                this.$emit("refreshGrid");
                this.$bvModal.hide("modal-create-job");
              });
            });
          }
        });
    },
    deleteJobLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            'Êtes-vous sûr de vouloir supprimer le métier "' + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.deleteJobs({
              jobIds: [id],
            }).then((res) => {
              this.$nextTick(() => {
                this.$emit("refreshGrid");
                this.$bvModal.hide("modal-create-job");
              });
            });
          }
        });
    },
    ...mapActions([
      "createJob",
      "updateJob",
      "getJobById",
      "archiveJobs",
      "restoreJobs",
      "deleteJobs",
    ]),
  },
  computed: {
    ...mapGetters(["isCreatingJob"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
};
</script>
